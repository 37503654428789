$gold: #ffd28f;
$bl: #303642;
$goldGradient: rgba(255, 210, 143, 0.4);
$blGradient: rgba(48, 54, 66, 0.5);
$wh: #ffffff;
$style1:#666665;


/*Taille d'écran*/

$ss: 425px;
$ms: 768px;
$ls: 1024px;

body {
  background-color: $bl;
  color: $wh;
}

#service,
#advantage,
.footer {
  margin: 0 auto;
  max-width: 1240px;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

@mixin title($degre) {
  padding-block: 130px;

  @media (max-width: $ms) {
    padding-block: 100px;
  }

  @media (max-width: $ss) {
    padding-block: 50px;
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 150px;
    position: relative;
    z-index: 1;

    h3 {
      color: $wh;
      font-size: 40px;
      font-weight: 900;
    }

    hr {
      width: 100%;
      height: 10px;
      border: none;
      background: linear-gradient($degre, transparent, $gold);
    }

    @media only screen and (max-width: $ls) {
      margin-bottom: 100px;
    }

    @media only screen and (max-width: $ls) {
      h3 {
        font-size: 30px;
      }
    }

    @media only screen and (max-width: $ss) {
      margin-bottom: 50px;

      h3 {
        font-size: 20px;
      }
    }
  }
}