#advantage {
    @include title(90deg);

    .cards-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-inline: 20px;
        gap: 30px;

        .card {
            background-color: $style1;
            width: 285px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            gap: 20px;
            box-shadow: 15px 20px 10px rgba(0, 0, 0, 0.25);

            @media (max-width: $ls) {
                display: flex;
                flex-direction: column;
                width: 325px;
            }

            svg {
                height: 100px;
                width: 100px;
                color: $gold;
            }

            h5 {
                margin-top: 35px;
                text-align: center;
                font-size: 1.2rem;

                @media (max-width: $ms) {
                    font-size: 22px;
                }
            }


            p {
                font-size: .90rem;
                color: $gold;

                @media (max-width: $ms) {
                    text-align: center;
                }

            }
        }

        @media (max-width: $ms) {
            gap: 80px;
        }
        @media (max-width: $ss) {
            padding-inline: 0;
        }
    }
}