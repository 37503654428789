.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:$bl ;

    display: flex;
    justify-content: center;
    align-items: center;
  
    h1 {
      color:$gold;
      height: auto;
      color: rgb(201, 171, 129);
      text-decoration: none;
      white-space: normal;
      width: 894px;
      min-height: 0px;
      min-width: 0px;
      max-height: none;
      max-width: none;
      text-align: center;
      line-height: 145px;
      letter-spacing: 28px;
      font-weight: 300;
      font-size: 50px;
    }
  }
  