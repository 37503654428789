#works {
  @include title(90deg);

  .cards-works {
    margin-bottom: 50px;
    padding-inline: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;

    .card {
      position: relative;
      width: 380px;
      background-color: $style1;
      box-shadow: 15px 20px 10px rgba(0, 0, 0, 0.25);

      img {
        width: 100%;
        height: 450px;
        object-fit: cover;
      }

      .text-card {
        display: flex;
        align-items: flex-end;
        background: linear-gradient(180deg, transparent 10%, $style1 80%);
        position: absolute;
        bottom: 0;
        padding: 20px;
        height: 50%;
        h5 {
          font-size: 0.9rem;
          font-weight: 550;
          color: $gold ;

          @media only screen and (max-width: $ls) {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
