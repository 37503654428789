#map {
    background-image: url("./assets/background-map1.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100%;
    position: relative;
    padding-block: 50px;
    border-block: 10px solid $gold;
    @include title(270deg);

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: $blGradient;
    }

    .map-img {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 850px;
        align-content: center;
        flex-wrap: wrap;
        position: relative;

        @media only screen and (max-width: $ms) {
            height: 480px;
            margin-bottom: 150px;
        }

        @media only screen and (max-width: $ss) {
            margin-bottom: 10px;
        }

        .img-border {
            border: 5px solid $gold;

            img {
                object-fit: cover;
                filter: grayscale(100%);
                width: 100%;
                height: 100%;
            }
        }
    }
}