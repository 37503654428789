#services {
  @include title(90deg);

  .cards-services {
    margin-bottom: 50px;
    padding-inline: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;

    .card {
      width: 350px;
      background-color: $style1;
      box-shadow: 15px 20px 10px rgba(0, 0, 0, 0.25);

      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
      }

      .text-card {
        margin: 8px;
        padding: 10px;

        h5 {
          color: $wh;
          font-size: 1.5rem;
          font-weight: 780;
          margin-bottom: 10px;

          @media only screen and (max-width: $ls) {
            font-size: 1rem;
          }
        }

        p {
          color: $gold;
          font-size: 0.8rem;

          @media only screen and (max-width: $ls) {
            font-size: 0.65rem;
          }
        }
      }
    }
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
      display: flex;
    }
    hr {
      width: 80%;
      color: $wh;
      border-top: 1px solid $wh;
    }
  }
}
