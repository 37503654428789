header {
  background-image: url("./assets/debarras1.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: $blGradient;
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $bl;
    border-bottom: 2px solid $gold;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 100;
    padding: 5px 20px;

    h1 {
      cursor: pointer;

      img {
        width: 80px;
        height: 80px;
      }
    }

    ul {
      display: flex;
      align-items: center;
      list-style-type: none;
      //font-weight: bold;
      font-size: 20px;
      gap: 45px;

      li {
        cursor: pointer;
        transition: text-decoration-color 0.3s ease-in,
          text-decoration-thickness 0.3s ease-in;
        font-size: 1rem;

        &:hover {
          text-decoration: underline;
          text-decoration-color: $gold;
          text-decoration-thickness: 4px;
        }

        a {
          color: $wh;
          text-decoration: none;
          transition: text-decoration-color 0.3s ease-in,
            text-decoration-thickness 0.3s ease-in;

          &:hover {
            text-decoration: underline;
            text-decoration-color: $gold;
            text-decoration-thickness: 4px;
          }
        }

        @media only screen and (max-width: 1024px) {
          font-size: 0.75rem;
        }
      }
    }

    .modal-button {
      display: none;
    }

    @media (max-width: $ms) {
      ul {
        display: none;
      }

      .modal-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: inherit;
        border-color: $gold;
        color: $gold;
        padding: 10px 20px;
      }
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $bl;
    z-index: 100;

    .close-modal {
      display: flex;
      justify-content: flex-end;

      button {
        background-color: inherit;
        border: none;

        svg {
          color: $wh;
          padding: 5px;
          margin: 20px;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      height: 100%;

      li {
        font-size: 2rem;
        text-align: center;
        font-weight: 900;
        padding-block: 75px;
        border-bottom: 2px solid $gold;

        &:last-child {
          border: none;
        }
      }
    }
  }

  .home {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    padding-inline: 50px;

    .border-img {
      width: 320px;
      height: 320px;
      background: linear-gradient(315deg, $goldGradient 10%, $gold 100%);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 95%;
        height: 95%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .home-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;

      h2 {
        color: $wh;
        font-size: 60px;
        font-weight: 900;

        @media only screen and (max-width: $ls) {
          font-size: 50px;
        }

        @media only screen and (max-width: $ss) {
          font-size: 40px;
        }
      }

      hr {
        width: 100%;
        margin-inline: 20px;
        background: linear-gradient(45deg, $gold, transparent);
        height: 10px;
        border: none;

        @media only screen and (max-width: $ss) {
          margin-inline: 0;
        }
      }

      h3 {
        margin-left: 20px;
        font-size: 23px;

        @media (max-width: $ss) {
          font-size: 20px;
        }
      }

      h3,
      p {
        color: $gold;
      }

      p {
        font-style: italic;
        font-size: 17px;
      }

      @media only screen and (max-width: $ms) {
        align-items: center;

        h2 {
          font-size: 2.5rem;
        }

        hr {
          margin: 0;
        }

        h3 {
          font-size: 1rem;
          text-align: center;
          margin-left: 0;
        }

        p {
          font-size: 0.75rem;
        }
      }

      @media only screen and (max-width: $ss) {
        h2 {
          font-size: 2rem;
        }

        hr {
          margin: 0;
        }

        h3 {
          font-size: 0.75rem;
          text-align: center;
          margin-left: 0;
        }

        p {
          font-size: 0.65rem;
        }
      }
    }

    @media only screen and (max-width: $ls) {
      align-content: center;
      padding-inline: 0;

      .border-img {
        width: 250px;
        height: 250px;
      }
    }

    @media only screen and (max-width: $ms) {
      flex-direction: column;

      .border-img {
        width: 200px;
        height: 200px;
      }
    }
  }
}

/** footer **/
footer {
  background-color: $bl;
  border-top: 25px solid $style1;

  .footer {
    display: flex;

    .footer-text {
      border-right: 25px solid $style1;
      gap: 20px;
      display: flex;
      flex-direction: column;
      padding: 10px;

      .text-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;

        @media (max-width: $ms) {
          gap: 10px;
        }

        h4 {
          padding: 3px;
          margin-top: 25px;
          margin-bottom: 10px;
          border: solid 2px $gold;
          display: inline-block;
          font-size: 20px;
        }

        .paragraph-block {
          width: 300px;
        }

        .footer-block-icon {
          display: flex;
          align-items: center;

          svg {
            width: 25px;
            height: 25px;
            margin-right: 10px;
            color: $gold;
          }

          a {
            color: #fff;
            text-decoration: none;
            transition: text-decoration-color 0.3s ease-in,
              text-decoration-thickness 0.3s ease-in;

            &:hover {
              text-decoration: underline;
              text-decoration-color: $gold;
              text-decoration-thickness: 2px;
            }
          }
        }

        p {
          font-size: 15px;
          margin: 5px;
        }

        @media only screen and (max-width: 768px) {
          align-items: center;
          text-align: center;

          .footer-block-icon {
            flex-direction: column-reverse;
            gap: 10px;
          }
        }
      }

      img {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100px;
        height: 100px;
        cursor: pointer;
      }
    }
    .mentions {
      color: $style1;
      font-size: 10px;
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
    }
    /*FORMULAIRE DE CONTACT*/
    .contact-footer {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 15px;
      gap: 10px;

      form {
        flex: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;

        input,
        textarea {
          padding: 10px;
          border: 1px solid $gold;
          font-size: 14px;
          width: 100%;
          background-color: $bl;
          color: $wh;

          &:focus {
            outline: $gold;
          }

          &::placeholder {
            color: $goldGradient;
          }
        }

        textarea {
          min-height: 200px;
        }

        .submit-button {
          display: flex;
          align-items: center;
          gap: 20px;

          button {
            padding: 9px;
            background-color: $bl;
            color: $gold;
            border: 1px solid $gold;
            cursor: pointer;
            font-size: 15px;
            font-weight: 500;
            transition: background-color 0.3s;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 45px;

            .send,
            .valid-send {
              display: none;
            }

            &:hover {
              background-color: $gold;
              color: $bl;
              padding: 10px;

              p {
                display: none;
              }

              .send {
                display: flex;
              }
            }
          }

          .submit-text {
            display: none;
            font-size: 0.75rem;
          }

          &.valid {
            button {
              p,
              .send {
                display: none;
              }

              .valid-send {
                display: flex;
              }
            }

            .submit-text {
              display: flex;
            }
          }

          @media only screen and (max-width: $ss) {
            flex-direction: column;
            width: 100%;

            .submit-text {
              text-align: center;
            }
          }
        }
      }

      .copyright {
        font-size: 10px;
        text-align: right;
        color: $style1;

        @media only screen and (max-width: $ss) {
          text-align: center;
        }
      }
    }
    .copyright a {
      text-decoration: none;
      color: $style1;
    }
    
    @media only screen and (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: center;

      .footer-text {
        border: none;
      }
    }
  }
}
